import React, { useEffect, useMemo, useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import SEO from "../components/seo";
import { RichText } from "prismic-reactjs";
import Img from "gatsby-image";
import clsx from "clsx";

export const query = graphql`
  query ArticleBySlug($uid: String!, $lang: String!) {
    prismicArticle(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        title {
          text
        }
        content {
          text
          raw
        }
        image {
          url
        }
        images {
          image {
            ...sharp
          }
        }
        link {
          url
        }
        category {
          uid
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  // console.log(data);
  const { title, content, image, images, link, category } =
    data.prismicArticle.data;

  const [loaded, setLoaded] = useState();

  useEffect(() => {
    setTimeout(_format, 250);
    window.addEventListener("resize", _format);

    return () => window.removeEventListener("resize", _format);
  }, []);

  const _format = () => {
    if (window.innerWidth < 1080) {
      setLoaded(true);
      return;
    }

    const menuCat = document.querySelector(
      `header nav a[data-uid=${category.uid}]`
    );
    if (menuCat) {
      const { top } = menuCat.getBoundingClientRect();
      document.documentElement.style.setProperty("--headerHeight", top + "px");
      // console.log(menuCat, top);
    }
    setLoaded(true);
  };

  return (
    <div className={clsx("page", loaded ? "is-loaded" : "")}>
      <SEO
        pageTitle={title.text}
        pageDescription={content.text}
        pageImage={image.url}
        template={`template-article`}
        page={true}
      />

      <div className="flex flex-col md:flex-row -mx-sm md:mx-0">
        <div className="col-md-4 col-xs-12">
          <div className="images ">
            {images &&
              images.map((el, i) => (
                <div className="mb-md shrink-0 wrapper-image" key={i}>
                  <Img {...el.image} Tag="figure" />
                </div>
              ))}
            {link && link.url && (
              <div className="dl text-center custom-underline mb-lg md:mb-0">
                <a href={link.url} target="_blank">
                  extract ⤓
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="col-md-6 col-xs-12">
          <h1 className="custom-underline">{title.text}</h1>

          <div className="texte ">{RichText.render(content.raw)}</div>
        </div>

        <div className="col-md-2 hidden-xs"></div>
      </div>
    </div>
  );
};

export default withPreview(Article);
